import { subMinutes } from "date-fns";
import {
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { useEffect, useMemo, useState } from "react";
import { db } from "../firestore";
import { Completed } from "./Completed";
import { EstimatedWait } from "./EstimatedWait";
import { Help } from "./Help";
import { OrderIdEntry } from "./OrderIdEntry";
import { Ready } from "./Ready";
import { RecentCompleted } from "./RecentCompleted";
import { RecentReady } from "./RecentReady";

export type Order = {
  id: string;
  orderId: string;
  readyAt?: Date;
  servedAt?: Date;
};

const convertDate = (firestoreDate: { seconds: number } | undefined) => {
  return firestoreDate ? new Date(firestoreDate.seconds * 1000) : undefined;
};

export const Layout = () => {
  const [orderId, setOrderId] = useState<string>(() => {
    return sessionStorage.getItem("orderId") || "";
  });

  const [showHelp, setShowHelp] = useState<boolean>(false);
  const [watch, setWatch] = useState<Order | null>(null);

  useEffect(() => {
    if (!orderId) {
      sessionStorage.removeItem("orderId");
      return;
    }
    sessionStorage.setItem("orderId", orderId.toString());
  }, [orderId]);

  const [activeOrders, setActiveOrders] = useState<Order[]>([]);
  const [recentlyServed, setRecentlyServed] = useState<
    (Order & { duration: number })[]
  >([]);

  const readyOrders = useMemo<string[]>(() => {
    return activeOrders
      .filter((order) => order.readyAt && !order.servedAt)
      .map((order) => order.orderId);
  }, [activeOrders]);

  const namedOrders = useMemo(() => {
    return [...activeOrders, ...recentlyServed]
      .filter((order) => isNaN(Number(order.orderId)) && !order.servedAt)
      .map((order) => order.orderId);
  }, [activeOrders, recentlyServed]);

  useEffect(() => {
    const q = query(
      collection(db, "orders"),
      where("servedAt", "==", null),
      orderBy("createdAt", "asc")
    );
    return onSnapshot(q, (querySnapshot) => {
      querySnapshot.docChanges().forEach((change) => {
        const id = change.doc.id;

        if (change.type === "added" || change.type === "modified") {
          const { orderId, ...rest } = change.doc.data();
          const readyAt = convertDate(rest.readyAt);
          const servedAt = convertDate(rest.servedAt);

          setActiveOrders((arr) => {
            const filtered = arr.filter((o) => o.id !== id);
            return [{ id, orderId, readyAt, servedAt }, ...filtered];
          });
        } else if (change.type === "removed") {
          setActiveOrders((arr) => arr.filter((order) => order.id !== id));
        }
      });
    });
  }, []);

  useEffect(() => {
    const halfHourAgo = subMinutes(new Date(), 60);

    const q = query(
      collection(db, "orders"),
      where("servedAt", ">=", halfHourAgo),
      orderBy("servedAt", "desc"),
      limit(3)
    );
    return onSnapshot(q, (querySnapshot) => {
      querySnapshot.docChanges().forEach((change) => {
        const id = change.doc.id;

        if (change.type === "added" || change.type === "modified") {
          const { orderId, ...rest } = change.doc.data();
          const readyAt = convertDate(rest.readyAt);
          const servedAt = convertDate(rest.servedAt);

          const duration =
            (rest.readyAt?.seconds || 0) - (rest.createdAt?.seconds || 0);

          setRecentlyServed((arr) => {
            const filtered = arr.filter((o) => o.id !== id);
            return [{ id, orderId, readyAt, servedAt, duration }, ...filtered];
          });
        } else if (change.type === "removed") {
          setRecentlyServed((arr) => arr.filter((order) => order.id !== id));
        }
      });
    });
  }, []);

  useEffect(() => {
    if (!orderId) return;
    const q = query(collection(db, "orders"), where("orderId", "==", orderId));
    return onSnapshot(q, (querySnapshot) => {
      querySnapshot.docChanges().forEach((change) => {
        const id = change.doc.id;
        const { orderId, ...rest } = change.doc.data();
        const readyAt = convertDate(rest.readyAt);
        const servedAt = convertDate(rest.servedAt);
        setWatch({ id, orderId, readyAt, servedAt });
      });
    });
  }, [orderId]);

  // useEffect(() => {
  //   if (!orderId) return;

  // Notification.requestPermission().then((permission) => {
  //   if (permission === "granted") {
  //     console.log("Notification permission granted.");
  //     // TODO(developer): Retrieve a registration token for use with FCM.
  //     // In many cases once an app has been granted notification permission,
  //     // it should update its UI reflecting this.
  //     messaging
  //       .getToken({
  //         vapidKey:
  //           "BI3mzIFwwB3wzwyehi0OQ7cFj3crDruoADj1EW0a1TiUOiwS6sO_Rm9o9x6xS7aWPid5gEnExMwZmsVCrC1-Iq4",
  //       })
  //       .then((currentToken) => {
  //         if (currentToken) {
  //           firestore
  //             .collection("tokens")
  //             .add({ orderId: orderId, token: currentToken });
  //         } else {
  //           console.log(
  //             "No registration token available. Request permission to generate one."
  //           );
  //         }
  //       })
  //       .catch((err) => {
  //         console.error(err);
  //         console.log("An error occurred while retrieving token. ", err);
  //       });
  //   } else {
  //     console.log("Unable to get permission to notify.");
  //   }
  // });
  // }, [orderId]);

  const detail = useMemo(() => {
    if (watch?.servedAt) return <Completed servedAt={watch.servedAt} />;
    if (watch?.readyAt) return <Ready />;
    if (showHelp) return <Help />;
    return <RecentReady readyOrders={readyOrders} />;
  }, [watch, readyOrders, showHelp]);

  return (
    <div className="flex flex-col justify-between h-full md:w-[500px] md:h-5/6 md:mx-auto md:border-2 md:rounded-lg md:border-t-0">
      <div className="flex bg-red-500 text-white h-[80px] gap-6 items-center justify-between px-4">
        <a href="https://www.cooperscatch.co.nz/" className="h-full">
          <img
            src="./logo.png"
            alt="logo.png"
            className="h-full py-2 object-contain"
          />
        </a>
        <div className="flex-grow text-2xl">Order Monitor</div>
        <button
          disabled={!!(watch?.readyAt || watch?.servedAt)}
          className="disabled:hidden"
          onClick={() => setShowHelp(!showHelp)}
        >
          {showHelp ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-10 w-10"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-10 w-10"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                clipRule="evenodd"
              />
            </svg>
          )}
        </button>
      </div>

      {!watch?.servedAt && !watch?.readyAt && !showHelp && (
        <EstimatedWait completedOrders={recentlyServed} />
      )}
      {!watch?.servedAt && !watch?.readyAt && !showHelp && (
        <RecentCompleted completedOrders={recentlyServed} />
      )}
      <div className="h-full p-2">{detail}</div>

      <div className="mx-auto px-6 pb-6">
        <OrderIdEntry
          namedOrders={namedOrders}
          onSubmit={(id) => {
            setWatch(null);
            setOrderId(id);
          }}
          onCancel={
            orderId
              ? () => {
                  setWatch(null);
                  setOrderId("");
                }
              : undefined
          }
        />
      </div>
    </div>
  );
};
