import { FC } from "react";

export const Ready: FC = () => {
  return (
    <div className="flex flex-col gap-2 bg-green-500 rounded-lg items-center justify-evenly p-6 text-white h-full">
      <div className="text-4xl text-center">Order Ready</div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-1/2 max-w-[150px]"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z" />
      </svg>
      <div className="text-xl text-center">
        Please collect your order at the counter
      </div>
    </div>
  );
};
