import { formatDuration, intervalToDuration } from "date-fns";
import { Order } from "./Layout";

type Props = {
  completedOrders: (Order & { duration: number })[];
};

export const EstimatedWait = ({ completedOrders }: Props) => {
  const averageDuration = completedOrders.reduce((acc, item, i) => {
    acc += item.duration;
    if (i === completedOrders.length - 1) {
      return acc / completedOrders.length;
    }
    return acc;
  }, 0);

  const estimatedWait = formatDuration(
    intervalToDuration({
      start: new Date(),
      end: new Date(new Date().getTime() + averageDuration * 1000),
    }),
    { format: ["minutes"] }
  );

  console.log({ estimatedWait });

  return (
    <div className="flex gap-4 p-2 items-center text-white bg-gray-800">
      <h1 className="text-md">Estimated Wait: </h1>
      <div className="flex gap-4">{estimatedWait}</div>
    </div>
  );
};
