export const Help = () => {
  return (
    <div className="flex flex-col gap-4 text-xl">
      <h2 className="text-2xl">Using the Tracker</h2>
      <p>Enter your order number, then check back here every few minutes.</p>
      <p>This page will update once your order is ready!</p>
      <p>
        You&apos;ll also see some recently served orders which may help in
        timing your pick up.
      </p>
    </div>
  );
};
