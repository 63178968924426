import { FC, useEffect, useMemo, useState } from "react";

type Props = {
  namedOrders: string[];
  onSubmit: (orderId: string) => void;
  onCancel?: () => void;
};

export const OrderIdEntry: FC<Props> = ({
  namedOrders,
  onSubmit,
  onCancel,
}) => {
  const orderIdParam = useMemo<string | null>(() => {
    const params = new URLSearchParams(window.location.search);
    return params.get("order") || null;
  }, []);

  useEffect(() => {
    if (!orderIdParam) return;
    onSubmit(orderIdParam);
  }, [orderIdParam, onSubmit]);

  const [autoFocus, setAutoFocus] = useState<boolean>(false);

  const [orderId, setOrderId] = useState<string>(() => {
    return orderIdParam || sessionStorage.getItem("orderId") || "";
  });

  const [isTextMode, setIsTextMode] = useState<boolean>(() => {
    return isNaN(Number(orderId));
  });

  const underlineStyle = " border-b-2 border-dotted border-blue-500";

  const suggestions = namedOrders.filter(
    (item) =>
      orderId.length > 0 && item.toLowerCase().startsWith(orderId.toLowerCase())
  );

  const orderIdValid = namedOrders.some((item) => item === orderId);

  return (
    <div className="flex flex-col gap-4">
      <div className="border-t-2 flex flex-col gap-2 pt-2">
        <div className="text-center text-xl text-gray-600">
          Enter your order{" "}
          <span
            onClick={() => {
              setAutoFocus(true);
              setOrderId("");
              onCancel?.();
              setIsTextMode(false);
            }}
            className={"text-blue-500" + (isTextMode ? "" : underlineStyle)}
          >
            number
          </span>
          <span> or </span>
          <span
            onClick={() => {
              setAutoFocus(true);
              setOrderId("");
              onCancel?.();
              setIsTextMode(true);
            }}
            className={"text-blue-500" + (isTextMode ? underlineStyle : "")}
          >
            name
          </span>
        </div>

        {!isTextMode ? (
          <input
            className="w-full outline-none text-center"
            readOnly={!!onCancel}
            style={{ fontSize: "min(32vw, 150px)" }}
            pattern="[0-9]*"
            inputMode="numeric"
            value={orderId}
            placeholder="_ _"
            autoFocus={autoFocus}
            onKeyDown={(event) => event.key === "Enter" && onSubmit(orderId)}
            onChange={(event) => {
              if (!Number.isInteger(Number(event.target.value))) {
                return;
              }
              setOrderId(event.target.value);
            }}
          />
        ) : (
          <>
            <div className="text-gray-600 h-28 gap-2 flex items-center justify-center flex-wrap">
              {!onCancel &&
                suggestions.map((suggestion) => (
                  <span
                    key={suggestion}
                    onClick={() => {
                      setOrderId(suggestion);
                      onSubmit(suggestion);
                    }}
                    className="border py-1 px-2 rounded text-2xl min-w-fit"
                  >
                    {suggestion}
                  </span>
                ))}
            </div>
            <input
              className="w-full outline-none text-center text-6xl"
              readOnly={!!onCancel}
              value={orderId}
              placeholder="_____"
              autoFocus={true}
              onKeyDown={(event) =>
                event.key === "Enter" && orderIdValid && onSubmit(orderId)
              }
              onChange={(event) => setOrderId(event.target.value)}
            />
          </>
        )}
      </div>

      {onCancel ? (
        <button
          className="rounded-lg bg-red-500 text-white py-2 px-10 text-3xl w-full"
          onClick={() => {
            setOrderId("");
            if (orderIdParam) window.location.search = "";
            onCancel();
          }}
        >
          CLEAR
        </button>
      ) : (
        <button
          className="rounded-lg bg-blue-500 disabled:bg-gray-400 text-white py-2 px-10 text-3xl w-full"
          disabled={!orderId || (isTextMode && !orderIdValid)}
          onClick={() => onSubmit(orderId)}
        >
          WATCH
        </button>
      )}
    </div>
  );
};
