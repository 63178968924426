type Props = {
  readyOrders: string[];
};

export const RecentReady = ({ readyOrders }: Props) => {
  return (
    <div className="flex flex-col h-full">
      <h1 className="text-2xl text-red-600">Orders Ready</h1>
      <div className="flex flex-wrap gap-4 py-6">
        {readyOrders.map((orderId, idx) => {
          return (
            <div key={orderId} className="text-center m-2 text-4xl">
              {orderId}
              {idx < readyOrders.length - 1 && ","}
            </div>
          );
        })}
      </div>
    </div>
  );
};
