import useErrorBoundary from "use-error-boundary";
import { Layout } from "./components/Layout";

const App = () => {
  const { ErrorBoundary, didCatch, error } = useErrorBoundary();

  if (didCatch) {
    return (
      <div className="w-screen h-screen p-4">
        <pre className="bg-red-300 w-full h-fit text-black whitespace-pre-wrap">
          {error.message}
        </pre>
      </div>
    );
  }

  return (
    <ErrorBoundary>
      <Layout />
    </ErrorBoundary>
  );
};

export default App;
