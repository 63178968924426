import { Order } from "./Layout";

type Props = {
  completedOrders: Order[];
};

export const RecentCompleted = ({ completedOrders }: Props) => {
  const sortedNumberedOrders = completedOrders
    // filters out phone and app orders.
    .filter((order) => !isNaN(parseInt(order.orderId, 10)))
    // orders by servedAt date so the list doesn't shift around as much
    .sort((a, b) => (new Date(a.servedAt!) < new Date(b.servedAt!) ? -1 : 1));

  const slicedOrders = sortedNumberedOrders.slice(
    sortedNumberedOrders.length > 2 ? sortedNumberedOrders.length - 3 : 0
  );

  return (
    <div className="flex gap-4 p-2 items-center text-white bg-gray-800">
      <h1 className="text-md">Recently Completed: </h1>
      <div className="flex justify-evenly gap-4">
        {slicedOrders.reverse().map((order, idx) => {
          return (
            <div
              key={order.orderId}
              className="text-white text-center text-2xl"
            >
              {order.orderId}
              {idx < slicedOrders.length - 1 && ","}
            </div>
          );
        })}
      </div>
    </div>
  );
};
