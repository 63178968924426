import firebase from "firebase/compat/app";
// import "firebase/messaging";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBnL1SoEy8_5DdnZ4pT0V-IZTO6NzBkf6Y",
  authDomain: "coopers-catch-35d73.firebaseapp.com",
  projectId: "coopers-catch-35d73",
  storageBucket: "coopers-catch-35d73.appspot.com",
  // messagingSenderId: "318647556288",
  appId: "1:318647556288:web:1a4fb1219800ad8c247525",
  measurementId: "G-GYJTMVMZPM",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

export const db = getFirestore(firebaseApp);

// if (firebase.messaging.isSupported()) {
//   const messaging = firebase.messaging();

//   messaging.onMessage((payload) => {
//     console.log("Message received. ", payload);
//     // ...
//   });
// }
